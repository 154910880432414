import * as React from "react"
import { Link, graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"

const seoTitle = "貢藤十六（くどう とうろく）｜ TANOSHIGE【たのしげ】"
const seoDescription = "ラジオDJ、ラジオパーソナリティの他にスポーツDJとしても活躍！ 他にもスポーツTV番組・YouTube動画・CMなどの声も多数出演しています。また近年、舞台俳優も経験し、幅広く活動を展開しています。"

// markup
const TorokuPage = ({ data }) => {
  return (
    <Layout isHomePage={false} headerClass="bg-black black-grade" isLogoOneWhite={true} isLogoTwoWhite={true}>
      <Seo title={seoTitle} description={seoDescription} />
      <main id="toroku">
        <section id="toroku-kv-box">
          <div id="toroku-ttl">
            <div className="inner">
              <h2>
                <StaticImage src="../images/ttl-toroku-white.png" alt="貢藤十六" />
              </h2>
            </div>
            <h3>番組MC　貢藤十六</h3>
          </div>
        </section>
        <section id="toroku-works">
          <h2><StaticImage src="../images/ttl-works.png" alt="WORKS" /></h2>
          <div className="inner">
            <figure>
              <a href="https://youtu.be/dixIHHPVxy4" target="_blank" rel="noreferrer"><StaticImage src="../images/works-1.png" alt="【広島市消防局】広島ノ消防隊「特殊能力者、現る。」：NA" /></a>
              <figcaption>
                【広島市消防局】広島ノ消防隊「特殊能力者、現る。」：NA
              </figcaption>
            </figure>
            <figure>
              <a href="https://youtu.be/ldF59szqQZ0" target="_blank" rel="noreferrer"><StaticImage src="../images/works-2.png" alt="【広島国際大学】ヒロコク向上委員会：NA" /></a>
              <figcaption>
                【広島国際大学】ヒロコク向上委員会：NA
              </figcaption>
            </figure>
            <h3>ラジオ</h3>
            <ul>
              <li><span className="em5">広島FM／</span><span>サンフレッチェ・ラジオ・サポーターズクラブ“貢藤十六のGOA～L”</span></li>
              <li><span className="em5">広島FM／</span><span>MORNING ALIVE（2006年10月～2009年3月）</span>
              </li>
              <li><span className="em5">広島FM／</span><span>THE男道（2012年4月～2013年3月）</span>
              </li>
              <li><span className="em5">広島FM／</span><span>5COLORS（2013年4月～2017年3月）</span></li>
              <li><span className="em6">ラジオ関西／</span><span>ジャイアンツナイター（2005年4月～9月）</span></li>
              <li><span className="em13">FM MOOV KOBE／</span><span>LOVE STREET（2004年4月～12月）</span></li>
            </ul>

            <h3>テレビ</h3>
            <ul>
              <li><span className="em6">スカパー！／</span><span>サンフレッチェTV：ナレーション</span></li>
              <li><span className="em6-5">RCCテレビ／</span><span>タグ・ホイヤー「時を駆ける～160年の軌跡」：出演</span></li>
              <li><span className="em6-5">RCCテレビ／</span><span>つじもと地産地消満菜食堂：NA</span></li>
              <li><span className="em6-5">RCCテレビ／</span><span>特別番組 我流でいこう：NA</span></li>
              <li><span className="em6">広島テレビ／</span><span>ぐるぐるスクール：NA</span></li>
              <li><span className="em6">広島テレビ／</span><span>よくばりアリス：NA</span></li>
              <li><span className="em7">テレビ新広島／</span><span>サンフレッチェ魂：NA</span></li>
              <li><span className="em6">広島テレビ／</span><span>金ぶち：NA　</span></li>
              <li><span className="em9">広島ホームテレビ／</span><span>サンフレ応援！森崎浩司の“Foot style”：出演</span></li>
            </ul>

            <h3>WEB配信</h3>
            <ul>
              <li>HIROSHIMA TANOSHIGE：番組MC</li>
              <li>RedBull CRASHEDICE 2017-2018 EDMONTON：ライブ配信</li>
              <li>RedBull CRASHEDICE 2018-2019 YOKOHAMA：ライブ配信</li>
              <li>RedBull ICECROSS 2019-2020 YOKOHAMA：ライブ配信</li>
              <li>ENJOY e-Sports メガ・エッグチャレンジカップ2020 E-Sports：実況</li>
            </ul>

            <h3>イベントMC</h3>
            <ul>
              <li>サンフレッチェ広島：スタジアムDJ</li>
              <li>2014FIFAワールドカップブラジル「コートジボワールVS日本」パブリックビューイングin東京ドーム：MC</li>
              <li>車いすラグビー・チャリティーイベント トークショー</li>
              <li>ベントレー エクストラ オーディナリー ディナー2019：MC</li>
              <li>ダイハツ コペン オーナーズ ミーティング2019：MC</li>
              <li>ホンダカーズ広島大運動会2019：MC</li>
              <li>ストライダーエンジョイカップ2019（＠広島＠熊本＠山形）：MC</li>
              <li>ラグビーワールドカップ2019ファンゾーン（＠神戸）：MC</li>
              <li>インクルーシブフットボールフェスタ2018.2020in広島</li>
              <li>浅田真央さんアイスパーティー（＠広島2018＠京都2019）：MC</li>
              <li>チャレスポ！TOKYO 2013年、2014年（東京都障害者スポーツ協会）</li>
              <li>JBSオールスターイン2012イン広島：アリーナDJ　その他多数</li>
            </ul>

            <h3>テレビ/ラジオ/WEBCM：NA</h3>
            <p>
              JR西日本、東亜ハウジング、創建ホーム、ライオンズマンション、山根木材、賀茂鶴酒造、イヴァンカ、バルコムＢＭＷ、丸大ハム、リクルート、GAP、スピングルムーブ、ワールドモータース、美装工舎、東広島市移住促進、池口精肉店、東亜地所、あなぶきグールプ、福留ハム、トータテ住宅販売、キャンディープロモーションライブ告知、広島大学理工学部、令和2年国勢調査、岡山ダイハツ、Audi広島、PEUGEOT広島　その他多数
            </p>

            <h3>企業紹介VP：NA</h3>
            <p>
              株式会社大創産業、株式会社イズミ、株式会社大下産業、山陽建設株式会社、明治安田生命広島支社社内ビデオ、オタフクソース株式会社、広島ガス株式会社　その他多数
            </p>

            <h3>施設</h3>
            <p>フレスタ：館内放送パーソナリティー</p>
          </div>
        </section>

        <Link className="link-btn" to="/"><span>TANOSHIGEトップへ戻る</span></Link>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpNews(sort: { fields: [date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          uri
          title
          slug
          date(formatString: "Do MMMM")
        }
      }
    }
  }
`

export default TorokuPage
